import "mapbox-gl/dist/mapbox-gl.css";

import * as React from "react";

import Layout from "./src/components/Layout";
import { LocalizationProvider } from "./src/context/LocalizationContext";
import { MapProvider } from "./src/context/MapContext";
import { MapSDKProvider } from "./src/context/MapSDKContext";
import { StoreProvider } from "./src/context/StoreContext";

export const wrapRootElement = ({ element }) => {
  return (
    <MapSDKProvider>
      <StoreProvider>
        <MapProvider>
          <LocalizationProvider>{element}</LocalizationProvider>
        </MapProvider>
      </StoreProvider>
    </MapSDKProvider>
  );
};

export const wrapPageElement = ({ element, props }) => (
  <Layout {...props}>{element}</Layout>
);