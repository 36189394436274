import { InfoOutlined } from "@mui/icons-material";
import { Button, Stack, useMediaQuery, useTheme } from "@mui/material";
import { graphql, useStaticQuery } from "gatsby";
import { IconButton } from "gatsby-theme-material-ui";
import * as React from "react";
import { ReactNode, useContext, useEffect, useState } from "react";

import LocalizationContext from "../context/LocalizationContext";
import MapContext from "../context/MapContext";
import StoreContext from "../context/StoreContext";
import { MapLpscsvQuery } from "../graphqlTypes";
import useInfoIconTranslationQuery from "../staticQueries/useInfoIconTranslationQuery";
import getMarker from "../utils/getMarker";
import Banner from "./Banner";
import GuideModal from "./GuideModal";
import LanguageIcon from "./icons/LanguageIcon";
import SatelliteIcon from "./icons/SatelliteIcon";
import Map from "./Map";

interface Props {
  children: ReactNode;
}

function OpenInfo() {
  const { infoIcon } = useInfoIconTranslationQuery();
  const [guideModal, setGuideModal] = useState(false);

  return (
    <>
      <Button
        sx={{
          position: "absolute",
          bottom: 0,
          right: 0,
          marginBottom: 4,
          marginRight: 4,
          zIndex: 1,
          borderRadius: "15px",
        }}
        variant="contained"
        startIcon={<InfoOutlined />}
        onClick={() => setGuideModal(true)}
      >
        {infoIcon}
      </Button>
      <GuideModal onClose={() => setGuideModal(false)} open={guideModal} />
    </>
  );
}

function SwitchLanguageDesktop() {
  const { iso, toggleLanguage } = useContext(LocalizationContext);

  return (
    <IconButton
      sx={{
        position: "absolute",
        top: 0,
        right: 0,
        marginTop: 4,
        marginRight: 4,
        zIndex: 1,
      }}
      onClick={toggleLanguage}
    >
      <LanguageIcon language={iso} />
    </IconButton>
  );
}

function Layout({ children }: Props): JSX.Element {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTabletOrDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const { toggleSatellite } = useContext(MapContext);
  const { updateMarkers, updateListData } = useContext(StoreContext);

  const { allLpsCsv } = useStaticQuery<MapLpscsvQuery>(graphql`
    query MapLPSCSV {
      allLpsCsv {
        nodes {
          id
          provider
          address
          openDaily
          openHoliday
          openWeekend
          type
          latLng
        }
      }
    }
  `);
  const LPSData = allLpsCsv.nodes;

  useEffect(() => {
    if (isTabletOrDesktop) {
      updateListData(LPSData);
      updateMarkers(LPSData.map(getMarker));
    }
  }, [isTabletOrDesktop, LPSData]);

  return (
    <main>
      <title>Pohotovosti</title>
      {isMobile && <Banner />}
      {isTabletOrDesktop && <Map />}
      {isMobile ? (
        children
      ) : (
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          spacing={2}
          position="absolute"
          top={0}
          paddingTop={4} // tenhle padding se mi nelibi, radsi bych margin, ale ten nejde, rozbije mi pak scroll...
          paddingBottom={4}
          marginLeft={4}
          zIndex={1}
          maxWidth="376px"
          maxHeight="100vh"
        >
          {children}
        </Stack>
      )}
      {isTabletOrDesktop && <SwitchLanguageDesktop />}
      {isTabletOrDesktop && (
        <IconButton
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            marginTop: 3.7,
            marginRight: 8,
            zIndex: 1,
          }}
          onClick={toggleSatellite}
        >
          <SatelliteIcon />
        </IconButton>
      )}
      {isTabletOrDesktop && <OpenInfo />}
    </main>
  );
}

export default Layout;
